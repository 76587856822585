import { fetchWithToken } from "../utils/fetch_with_token"

const initStripeSub = () => {
  var stripe = Stripe(gon.stripe_key);
    // load the fonts in
  var elements = stripe.elements({
      fonts: [
    {
      // integrate your font into stripe
      cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:400,500',
    }
  ]
  }
    );

  var cardElement = elements.create('card',
  {
    hidePostalCode: true,
    style: {
            base: {
                    iconColor: '#F99A52',
                    color: '#32315E',
                    fontWeight: 400,
                    fontFamily: '"Montserrat", "Montserrat", sans-serif',
                    fontSize: '16px',

                  },
            }
  });
  cardElement.mount('#sub-card');

  const form = document.getElementById("update-subscription");
  const submitBtn = document.querySelector('#submitBtn');
  const spinner = document.getElementById("spinner");
  submitBtn.addEventListener('click', function(e) {
    e.preventDefault();
    spinner.classList.remove("d-none");
    const totalPrice = document.getElementById('js-total-price').dataset.totalPrice;
    // Step 1: POST request to create subscription intent
    const paymentMethod = stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    .then((result) => {
      // Handle result.error or result.paymentMethod
      fetchWithToken(`/stripe_setup_intents`, {
        method: "POST",
        headers: {
          accept: "application/json",
          'Content-Type': "application/json"
        },
        body: JSON.stringify({payment_method: result.paymentMethod})
      })
      .then((response) => response.json())
      .then((setupIntent) => {
        stripe.confirmCardSetup(setupIntent.client_secret)
        .then((result) => {
          if (result.error) {
            spinner.classList.add("d-none");
            alert(result.error.message);
          } else {
            fetchWithToken(`/stripe_subscriptions`, {
              method: "POST",
              headers: {
                accept: "application/json",
                'Content-Type': "application/json"
              },
              body: JSON.stringify({payment_method: result.setupIntent.payment_method, price: totalPrice, card_info: cardElement })
            })
            .then((response) => response.json())
            .then((stripeSub) => {
              console.log(stripeSub);
              // Step 3: Embed payment ID in form
              const subIdInput = document.querySelector('#subscription_id');
              subIdInput.value = stripeSub.stripe_sub;
              form.submit();
            })
          }
        })
      })

    })
  });
}

export { initStripeSub };
