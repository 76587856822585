const lpScrolls = () => {
  const btn1 = document.getElementById("slide-1")
  btn1.addEventListener("click", () => {
    document.getElementById("slide-1-target").scrollIntoView({ behavior: "smooth" });
  });
  const btn2 = document.getElementById("slide-2")
  btn2.addEventListener("click", () => {
    document.getElementById("slide-2-target").scrollIntoView({ behavior: "smooth" });
  });
  const btn3 = document.getElementById("slide-3")
  btn3.addEventListener("click", () => {
    document.getElementById("slide-3-target").scrollIntoView({ behavior: "smooth" });
  });
  const btn4 = document.getElementById("slide-4")
  btn4.addEventListener("click", () => {
    document.getElementById("slide-4-target").scrollIntoView({ behavior: "smooth" });
  });
};

export { lpScrolls };
