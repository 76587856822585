
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"


const initFlatpickr = () => {
  const calendar = document.querySelector('.datepicker');
  if (calendar) {
    const today = new Date().toJSON();
    const oneMonthAgo = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 3,
        new Date().getDate()
    ).toJSON();
    flatpickr(calendar, {
        mode: "range",
        altInput: true,
        altFormat: "j F Y",
        altInputClass: "btn btn-light btn-sm d-flex flex-grow-1 mr-1 mt-0",
        defaultDate: gon.dates,
        "locale": French // locale for this instance only
    });
  }

  const calendar2 = document.querySelector('.calendar');
  console.log(calendar2);
  if (calendar2) {
    var today = new Date();
    today.setMinutes (today.getMinutes() + 60);
    today.setMinutes (0);
    flatpickr(calendar2, {
        altInput: true,
        enableTime: true,
        altFormat: "j F Y H:i",
        minuteIncrement : 15,
        defaultDate: today,
        minDate: today,
        "locale": French // locale for this instance only
    });
  }

};

export { initFlatpickr };
